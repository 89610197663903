/**************************************
    -- Css Table of Content
    01.Generale Style
    02.Preloader Style
    03.Navber Style
    04.Home Section Style
    05.About Section Style
    06.Services Section Style
    07.Resume Section Style
    08.Portfolio Section Style
    09.Testimonial Section Style
    10.Blog Section Style
    11.Contact Section Style
    12.Map Section Style
    13.Footer Section Style
 *************************************/
 
/**************************************
 * 01. Generale Style
 *************************************/

 
 body {
     background: #0c0f16;
     color: #fff;
     font-family: 'Rubik', sans-serif;
     font-size: 14px;
     font-weight: normal;
     line-height: 24px;
     overflow-x: hidden;
     margin: 0 auto;
     padding: 0;
     -moz-box-sizing: border-box;
     -ms-box-sizing: border-box;
     -o-box-sizing: border-box;
     -webkit-box-sizing: border-box;
     box-sizing: border-box;
 }
 .bg-dark {
     background-color: #11141b !important;
 }
 
 h1, h2, h3, h4, h5, h6 {
     margin: 0;
     font-family: 'Rubik', sans-serif;
     font-weight: normal;
     color: #ffffff;
     line-height: 1.3;
 }
 
 a {
     color: #f55422;
     text-decoration: none !important;
     -moz-transition: all 0.5s ease-in-out 0s;
     -ms-transition: all 0.5s ease-in-out 0s;
     -o-transition: all 0.5s ease-in-out 0s;
     -webkit-transition: all 0.5s ease-in-out 0s;
     transition: all 0.5s ease-in-out 0s;
 }
 span, a, a:hover {
     display: inline-block;
     text-decoration: none;
     color: inherit;
 }
 img {
     max-width: 100%;
 }
 
 a:hover {
     color: #f55422;
     -moz-transition: all 0.4s ease-in-out 0s;
     -ms-transition: all 0.4s ease-in-out 0s;
     -o-transition: all 0.4s ease-in-out 0s;
     -webkit-transition: all 0.4s ease-in-out 0s;
     transition: all 0.4s ease-in-out 0s;
 }
 
 a:hover, a:focus {
     color: #f55422;
     text-decoration: none;
 }
 
 p {
     padding: 0;
     margin: 0;
     font-size: 14px;
     color: #c5c5c5;
     line-height: 24px;
     font-family: 'Rubik', sans-serif;
 }
 
 .z-index {
     z-index: 9;
 }
 
 .position-relative {
     position: relative!important;
 }
 
 .form-control:focus {
     box-shadow: none;
     outline: 0 none;
     border-bottom: 1px solid #f55422;
 }
 
 .btn:focus {
     outline: none !important;
     box-shadow: none;
 }
 
 button:focus {
     outline: 0 none;
     box-shadow: none;
 }
 
 .button:focus {
     outline: none;
     box-shadow: none;
 }
 
 .button {
     display: inline-block;
     padding: 8px 30px;
     text-align: center;
     vertical-align: middle;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     font-size: 14px;
     text-transform: capitalize;
     letter-spacing: 0.5px;
     font-weight: 500;
     border: 2px solid #f55422;
     border-radius: 30px;
     background: #f55422;
     transition: all 500ms ease;
     cursor: pointer;
     color: #fff;
     margin-top: 25px;
 }
 
 .button:hover {
     color: #fff;
     background: transparent;
     border: 2px solid #f55422;
 }
 
 .after-btn {
     position: relative;
     padding: 9px 15px;
     display: inline-block;
     text-transform: uppercase;
     letter-spacing: 4px;
     font-size: 15px;
     color: #fff !important;
     font-weight: 400;
     background: transparent;
     font-family: 'Teko', sans-serif;
 }
 .after-btn:after {
     content: '';
     width: 45%;
     height: 100%;
     background: rgba(255, 255, 255, 0.07);
     position: absolute;
     left: 0;
     top: 0;
     transition: all 500ms ease;
 }
 .after-btn:hover:after {
     width: 100%;
 }
 
 dl, ol, ul {
     padding: 0;
     margin: 0;
     list-style: none;
 }
 
 .bg-fixed {
     background-attachment: fixed;
 }
 
 .bg-img {
     background-size: cover;
     background-repeat: no-repeat;
 }
 
 .section-padding {
     padding: 100px 0;
 }
 
 .section-title {
     text-align: center;
     margin-bottom: 75px;
 }
 
 .section-title h2 {
     position: relative;
     font-weight: 700;
     padding-bottom: 20px;
     font-size: 40px;
     line-height: 1.3;
     text-transform: capitalize;
     color: #fff;
     font-family: 'Rubik', sans-serif;
 }
 .section-title h2:after {
     background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgb(165, 165, 165), rgba(0, 0, 0, 0));
     background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
     background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
     background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=1);
     position: absolute;
     left: 0;
     right: 0;
     height: 1px;
     width: 100%;
     content: "";
     bottom: 0px;
     max-width: 300px;
     margin: 0 auto;
 }
 
 .display-table {
     width: 100%;
     height: 100%;
     display: table;
 }
 
 .table-cell {
     display: table-cell;
     vertical-align: middle;
 }
 
 .mr-top-50 {
     margin-top: 50px;
 }
 
 .back-to-top {
     position: fixed;
     display: none;
     color: #fff;
     width: 40px;
     height: 42px;
     line-height: 45px;
     border-radius: 0;
     right: 25px;
     bottom: 20px;
     text-align: center;
     background: #f55422;
     transition: background 0.5s;
     z-index: 11;
 }
 
 .back-to-top i {
     color: #fff;
     font-size: 20px;
 }
 
 .v-middle {
     position: absolute;
     width: 100%;
     top: 50%;
     left: 0;
     -webkit-transform: translate(0%, -50%);
     transform: translate(0%, -50%);
 }
 
 .valign {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
 }
 .owl-theme .owl-nav.disabled+.owl-dots {
     margin-top: 30px;
 }
 .owl-theme .owl-dots .owl-dot span {
     width: 8px;
     height: 8px;
     margin: 5px 7px;
     background: #D6D6D6;
     display: block;
     -webkit-backface-visibility: visible;
     transition: opacity .2s ease;
     border-radius: 30px;
 }
 
 /**************************************
  * 02.Preloader Style
  *************************************/
 .loader {
   position: fixed;
   top: 0;
   bottom: 0;
   width: 100%;
   background-color: #11141b;
   z-index: 999999;
 }
 .loader .loader-outter {
   position: absolute;
   border: 2px solid #ffc209;
   border-left-color: transparent;
   border-radius: 50%;
   width: 50px;
   height: 50px;
   left: calc(50% - 15px);
   top: calc(50% - 15px);
   -webkit-animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
   animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
 }
 .loader .loader-inner {
   position: absolute;
   border: 2px solid #ffc209;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   left: calc(50% - 10px);
   top: calc(50% - 10px);
   border-right: 0;
   border-top-color: transparent;
   -webkit-animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
   animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
 }
 
 @-webkit-keyframes loader-outter {
   0% {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }
 @keyframes loader-outter {
   0% {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }
 @-webkit-keyframes loader-inner {
   0% {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(-360deg);
     transform: rotate(-360deg);
   }
 }
 @keyframes loader-inner {
   0% {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(-360deg);
     transform: rotate(-360deg);
   }
 }
 
 /**************************************
  * 03.Navber Style
  *************************************/
 .navbar-b {
     transition: all .5s ease-in-out;
     background-color: transparent;
     padding-top: 1.563rem;
     padding-bottom: 1.563rem;
 }
 
 .navbar-b.navbar-reduce {
     box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
 }
 
 .navbar-b.navbar-trans .nav-item, .navbar-b.navbar-reduce .nav-item {
     position: relative;
     padding-right: 10px;
     padding-left: 0;
 }
 
 .navbar-b.navbar-trans .nav-link, .navbar-b.navbar-reduce .nav-link {
     color: #fff;
     text-transform: capitalize;
     font-weight: 500;
     font-size: 14px;
     letter-spacing: 0.5px;
 }
 
 .navbar-b.navbar-trans .nav-link:hover, .navbar-b.navbar-reduce .nav-link:hover {
     color: #333;
 }
 
 .navbar-b.navbar-trans .nav-link:hover {
     color: #ffffff;
 }
 
 .navbar-b.navbar-trans .show > .nav-link, .navbar-b.navbar-trans .active > .nav-link, .navbar-b.navbar-trans .nav-link.show, .navbar-b.navbar-trans .nav-link.active {
     color: #fff;
 }
 
 .navbar-b.navbar-reduce {
     transition: all .5s ease-in-out;
     background-color: #fff;
     padding-top: 20px;
     padding-bottom: 20px;
 }
 
 .navbar-b.navbar-reduce .nav-link {
     color: #333;
 }
 
 .navbar-b.navbar-reduce .nav-link:hover {
     color: #333;
 }
 
 .navbar-b.navbar-reduce .show > .nav-link, .navbar-b.navbar-reduce .active > .nav-link, .navbar-b.navbar-reduce .nav-link.show, .navbar-b.navbar-reduce .nav-link.active {
     color: #333;
 }
 
 .navbar-b.navbar-reduce .navbar-brand {
     color: #333;
 }
 
 .navbar-b.navbar-reduce .navbar-toggler span {
     background-color: #1B1B1B;
 }
 
 .navbar-b .navbar-brand {
     color: #fff;
     font-size: 28px;
     font-weight: 800;
     font-family: 'Rubik', sans-serif;
 }
 
 .navbar-b .navbar-nav .dropdown-item.show .dropdown-menu, .navbar-b .dropdown.show .dropdown-menu, .navbar-b .dropdown-btn.show .dropdown-menu {
     -webkit-transform: translate3d(0px, 0px, 0px);
     transform: translate3d(0px, 0px, 0px);
     visibility: visible !important;
 }
 
 .navbar-b .dropdown-menu {
     margin: 1.12rem 0 0;
     border-radius: 0;
 }
 
 .navbar-b .dropdown-menu .dropdown-item {
     padding: .7rem 1.7rem;
     transition: all 500ms ease;
 }
 
 .navbar-b .dropdown-menu .dropdown-item:hover {
     background-color: #f55422;
     color: #fff;
     transition: all 500ms ease;
 }
 
 .navbar-b .dropdown-menu .dropdown-item.active {
     background-color: #f55422;
 }
 
 .navbar-toggler {
     position: relative;
 }
 
 .navbar-toggler:focus, .navbar-toggler:active {
     outline: 0;
 }
 
 .navbar-toggler span {
     display: block;
     background-color: #fff;
     height: 3px;
     width: 25px;
     margin-top: 4px;
     margin-bottom: 4px;
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
     left: 0;
     opacity: 1;
 }
 
 .navbar-toggler span:nth-child(1), .navbar-toggler span:nth-child(3) {
     transition: -webkit-transform .35s ease-in-out;
     transition: transform .35s ease-in-out;
     transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
 }
 
 .navbar-toggler:not(.collapsed) span:nth-child(1) {
     position: absolute;
     left: 12px;
     top: 10px;
     -webkit-transform: rotate(135deg);
     transform: rotate(135deg);
     opacity: 0.9;
 }
 
 .navbar-toggler:not(.collapsed) span:nth-child(2) {
     height: 12px;
     visibility: hidden;
     background-color: transparent;
 }
 
 .navbar-toggler:not(.collapsed) span:nth-child(3) {
     position: absolute;
     left: 12px;
     top: 10px;
     -webkit-transform: rotate(-135deg);
     transform: rotate(-135deg);
     opacity: 0.9;
 }
 
 /**************************************
  * 04.Home Section Style
  *************************************/
 .home-area {
     height: 100vh;
     position: relative;
     color: #fff;
     text-align: center;
     /* background: url(../img/home-bg.jpg); */
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center center;
     background-attachment: fixed;
     z-index: 1;
     padding-bottom: 0px;
 }
 
 .home-area::after {
     position: absolute;
     top: 0;
     content: "";
     left: 0px;
     padding: 0;
     height: 100%;
     width: 100%;
     opacity: .5;
     z-index: 1;
     background: rgb(3 5 16);
 }
 
 .hero-equal-height {
     position: relative;
     min-height: 660px;
     -js-display: flex;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     height: 100%;
     width: 100%;
 }
 
 .home-content h3 {
     font-size: 18px;
     margin-bottom: 25px;
     text-transform: uppercase;
     font-weight: 500;
     letter-spacing: 3px;
 }
 
 .home-content h1 {
     font-size: 60px;
     color: #fff;
     margin-bottom: 20px;
     font-weight: 700;
     line-height: 1.3;
 }
 .cd-words-wrapper {
     display: inline-block;
     position: relative;
     text-align: left;
 }
 
 .cd-words-wrapper b {
     display: inline-block;
     position: absolute;
     white-space: nowrap;
     left: 0;
     top: 0;
 }
 
 .cd-words-wrapper b.is-visible {
     position: relative;
 }
 
 .cd-headline.clip span {
     display: inline-block;
 }
 
 .cd-headline.clip .cd-words-wrapper {
     overflow: hidden;
     vertical-align: top;
     color: #ffffff;
     font-weight: 500;
     letter-spacing: 0px;
 }
 
 .cd-headline.clip .cd-words-wrapper::after {
     content: '';
     position: absolute;
     top: 0;
     right: 0;
     width: 2px;
     height: 100%;
     background-color: #fff;
 }
 
 .cd-headline.clip b {
     opacity: 0;
 }
 
 .cd-headline.clip b.is-visible {
     opacity: 1;
 }
 .home-content .home-btn-1 {
     margin-right: 15px;
 }
 .social-media a {
     display: inline-block;
     padding: 0px 5px;
     margin: 0px 8px;
     color: #fff;
 }
 .social-media a:hover {
     opacity: .5;
 }
 
 .home-area .arrow {
     position: absolute;
     bottom: 5vh;
     width: 100%;
     text-align: center;
     font-size: 13px;
     z-index: 8;
 }
 .home-area .arrow i {
     position: relative;
     display: inline-block;
     width: 30px;
     color: #fff;
     height: 30px;
     line-height: 30px;
 }
 .home-area .arrow i:after {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(255, 255, 255, 0.2);
     border-radius: 50%;
     z-index: -1;
     -webkit-transition: all .2s;
     transition: all .2s;
 }
 .home-area .arrow i:hover:after {
     -webkit-transform: scale(1.2);
     transform: scale(1.2);
     opacity: 0;
     -webkit-transition: all .5s;
     transition: all .5s;
 }
 
 /**************************************
  * 05. About Section Style
  *************************************/
 
 .about-img-wrapper {
     border: 15px solid #fff;
     border-radius: 5px;
 }
 
 .about-img-wrapper img {
     width: 100%;
 }
 
 .about-content {
     padding-right: 0px;
 }
 
 .about-content h3 {
     font-size: 25px;
     font-weight: 700;
 }
 .about-content h6 {
     margin: 10px 0 30px;
     color: #c3c3c3;
     font-weight: 400;
     font-size: 13px;
     text-transform: uppercase;
     letter-spacing: 4px;
 }
 .about-content p {
     margin-bottom: 20px;
 }
 .about-content p:nth-last-child(-n + 2) {
     margin-bottom: 0px;
 }
 .color-text {
     color: #ffc209;
 }
 
 .about-content .after-btn {
     margin-top: 25px;
 }
 /**************************************
  * 06. Services Section Style
  *************************************/
 
 .single-services-box {
     position: relative;
     padding: 45px 25px;
     border-radius: 3px;
     background-color: #151921;
     text-align: center;
 }
 .single-services-box:before {
   position: absolute;
   content: "";
   left: 0px;
   right: 0px;
   bottom: 0;
   min-width: 100%;
   border-bottom: 2px solid #f55422;
     border-bottom-left-radius: 3px;
     border-bottom-right-radius: 3px;
   transform: scaleX(0);
   -webkit-transition: all 500ms ease;
   -moz-transition: all 500ms ease;
   -ms-transition: all 500ms ease;
   -o-transition: all 500ms ease;
   transition: all 500ms ease;
 }
 
 .single-services-box:hover:before {
   transform: scaleX(1);
 }
 .single-services {
 }
 
 .services-icon {
     margin-bottom: 30px;
 }
 
 .services-icon i {
     color: #f55422;
     font-size: 40px;
     width: 70px;
     height: 70px;
     line-height: 70px;
     text-align: center;
     display: inline-block;
     position: relative;
     -webkit-transition: all .4s;
     transition: all .4s;
     z-index: 3;
 }
 .services-icon i:after {
     content: '';
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     position: absolute;
     border-radius: 20px;
     background: rgba(200, 200, 200, 0.07);
     z-index: -1;
     -webkit-transform: rotate(45deg);
     transform: rotate(45deg);
     -webkit-transition: all .4s;
     transition: all .4s;
 }
 .single-services-box:hover .services-icon i {
     color: #fff;
 }
 .single-services-box:hover .services-icon i:after {
     background: #f55422;
 }
 .services-text h3 {
     font-size: 17px;
     margin-bottom: 15px;
     color: #ffffff;
     font-weight: 500;
     letter-spacing: 1px;
 }
 
 /**************************************
  * 07.Resume Section Style
  *************************************/
 
 .work-eduction-wrapper {
     margin-bottom: 100px;
 }
 .bg-base-color {
     background-color: #f55422;
 }
 .timeline {
   padding: 30px 30px 40px 30px;
   border-radius: 3px;
   background-color: #0c0f16;
   margin-bottom: 30px;
   -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
           box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
 }
 
 .timeline-item {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
 }
 
 .timeline-item .icon {
   position: relative;
 }
 
 .timeline-item .icon:after {
   position: absolute;
   content: "";
   left: auto;
   top: auto;
   width: 8px;
   height: 8px;
   background-color: #f55422;
   border-radius: 50%;
   margin-top: 10px;
   margin-left: -3px;
 }
 
 .timeline-item .icon:before {
   position: absolute;
   content: "";
   left: auto;
   top: auto;
   width: 1px;
   height: calc(100% - 30px);
   background-color: #f55422;
   margin-top: 30px;
 }
 
 .timeline-item .box {
   padding-left: 50px;
   margin-bottom: 50px;
 }
 
 .timeline-item .box h5 {
     font-size: 18px;
     margin-bottom: 8px;
 }
 
 .timeline-item .box small {
     color: #ffffff;
     padding: 5px 12px;
     font-weight: 500;
     font-size: 10px;
     letter-spacing: 0.5px;
 }
 
 .timeline-item .box p {
   margin-top: 15px;
   margin-bottom: 0;
 }
 
 .timeline-item:last-child .box {
   margin-bottom: 0px;
 }
 
 /*  Skills  */
 .skill-item {
   margin-bottom: 30px;
 }
 
 .skill-item h6 {
   font-size: 15px;
   font-weight: 500;
   margin-bottom: 5px;
 }
 
 .skill-item .skill-progress {
   background-color: #eee;
   border-radius: 20px;
 }
 
 .skill-item .skill-progress-bar {
   width: 0;
   height: 4px;
   position: relative;
   border-radius: 20px;
   -webkit-transition: all 0.5s ease;
   transition: all 0.5s ease;
 }
 
 .skill-item .skill-progress-bar span {
   position: absolute;
   right: 0;
   top: -24px;
   font-size: 13px;
   font-weight: 500;
 }
 
 /**************************************
  * 08.Portfolio Section Style
  *************************************/
 #project {
     position: relative;
 }
 
 #project .project-list {
     text-align: center;
 }
 
 #project .project-list .nav {
     display: inline-block;
     margin: 0;
     position: relative;
 }
 
 #project .project-list .nav li {
     position: relative;
     float: left;
     font-size: 14px;
     line-height: 15px;
     cursor: pointer;
     font-weight: 400;
     letter-spacing: 0.5px;
     text-transform: capitalize;
     display: inline-block;
     margin: 0 15px 20px;
     padding: 10px 20px;
     border-radius: 20px;
     color: #ffffff;
     background-color: #2b2b2b;
     -moz-transition: all 0.5s ease-in-out 0s;
     -ms-transition: all 0.5s ease-in-out 0s;
     -o-transition: all 0.5s ease-in-out 0s;
     -webkit-transition: all 0.5s ease-in-out 0s;
     transition: all 0.5s ease-in-out 0s;
     box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.07);
 }
 
 #project .project-list .nav li:hover, #project .project-list .nav li.filter-active {
     color: #fff;
     background: #f55422;
     -moz-transition: all 0.5s ease-in-out 0s;
     -ms-transition: all 0.5s ease-in-out 0s;
     -o-transition: all 0.5s ease-in-out 0s;
     -webkit-transition: all 0.5s ease-in-out 0s;
     transition: all 0.5s ease-in-out 0s;
 }
 
 #project .project-container {
     display: inline-block;
     width: 100%;
 }
 
 .project-area .project-grid-item {
     overflow: hidden;
 }
 
 .project-grid-item img {
     width: 100%;
     border-radius: 5px;
 }
 
 .project-item {
     position: relative;
     display: block;
     overflow: hidden;
     margin-top: 30px;
 }
 
 .project-item .project-img-overlay {
     position: absolute;
     z-index: 2;
     top: 5px;
     right: 5px;
     bottom: 5px;
     left: 5px;
     height: auto;
     opacity: 0;
     -webkit-transition: all .5s;
     transition: all .5s;
     -webkit-transform: scale(1.1);
     transform: scale(1.1);
     background-color: rgba(0, 0, 0, 0.7);
 }
 .project-item:hover .project-img-overlay {
     opacity: 1;
     border-radius: 5px;
     -webkit-transform: scale(.98);
     -moz-transform: scale(.98);
     -ms-transform: scale(.98);
     -o-transform: scale(.98);
     transform: scale(.98);
 }
 
 .project-item .project-img-overlay .project-content {
     position: absolute;
     top: 50%;
     left: 0;
     transform: translateY(-50%);
     width: 100%;
     text-align: center;
     z-index: 2;
     -webkit-transition: .5s;
     transition: .5s;
     overflow: hidden;
 }
 
 
 .project-item .project-img-overlay .project-content h4 {
     color: #fff;
     font-size: 18px;
     letter-spacing: 0.5px;
     font-weight: 500;
     position: relative;
     margin-bottom: 10px;
     text-transform: capitalize;
     -webkit-transform: translateY(-15px);
     transform: translateY(-15px);
     opacity: 0;
     z-index: 7;
     -webkit-transition: all .6s;
     transition: all .6s;
 }
 
 .project-item .project-img-overlay .project-content h6 {
     color: #ffc209;
     margin-top: 5px;
     font-size: 14px;
     letter-spacing: 0.9px;
     position: relative;
     font-weight: 500;
     -webkit-transform: translateY(15px);
     transform: translateY(15px);
     opacity: 0;
     z-index: 7;
     -webkit-transition: all .6s;
     transition: all .6s;
 }
 
 .project-item:hover .project-img-overlay .project-content h4,
 .project-item:hover .project-img-overlay .project-content h6 {
     -webkit-transform: translateY(0);
     transform: translateY(0);
     opacity: 1;
 }
 .project-more-btn {
     margin-top: 40px;
 }
 
 /**************************************
  * 09.Testimonial Section Style
  *************************************/
 .testimonial-area {
     /* background: url(../img/review-bg.png); */
     background-repeat: no-repeat;
     background-size: cover;
 }
 
 .single-testimonial {
     position: relative;
     background: transparent;
     padding: 0px 30px;
     border-radius: 3px;
     z-index: 1;
     transition: all 0.3s ease-in-out;
     -webkit-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
 }
 
 
 .single-testimonial .testimonial-bio {
     text-align: center;
 }
 
 .testi-content-inner .avatar {
     margin: 0 auto;
     margin-bottom: 25px;
 }
 .single-testimonial .avatar img {
     border-radius: 50%;
     border: 5px solid #ffffff;
     width: 110px;
     margin: 0 auto;
     border-left-color: #f55422;
     border-bottom-color: #f55422;
 }
 .single-testimonial .bio-info {
     text-align: center;
 }
 .single-testimonial .bio-info h3 {
     font-size: 17px;
     color: #ffffff;
     font-weight: 500;
     display: inline-block;
     padding-right: 10px;
     margin-right: 10px;
     position: relative;
     text-transform: uppercase;
     letter-spacing: 2px;
     font-family: 'Teko', sans-serif;
 }
 .single-testimonial .bio-info h3:after {
     content: "|";
     position: absolute;
     right: -4px;
     bottom: 2px;
 }
 .single-testimonial .bio-info h6 {
     display: inline-block;
     font-size: 12px;
     letter-spacing: 2px;
 }
 
 .single-testimonial .testimonial-content {
     margin-bottom: 20px;
     text-align: center;
 }
 
 .single-testimonial .testimonial-content p {
     font-size: 16px;
     color: #ccc;
     font-style: italic;
     line-height: 1.8;
     letter-spacing: 1px;
 }
 
 /**************************************
  * 10.Blog Section Style
  *************************************/
 
 .blog-area {
 }
 .blog-area .single-blog {
     position: relative;
     background: #0e121b;
 }
 .blog-area .post-img {
     position: relative;
     overflow: hidden;
     border-radius: 3px;
 }
 .blog-area .single-blog img {
     -webkit-transition: all .5s;
     transition: all .5s;
 }
 .blog-area .single-blog:hover img {
     -webkit-filter: none;
     filter: none;
     -webkit-transform: rotate(3deg) scale(1.1, 1.1);
     -ms-transform: rotate(3deg) scale(1.1, 1.1);
     transform: rotate(3deg) scale(1.1, 1.1);
 }
 
 .blog-area .single-blog .blog-content {
     padding: 30px 25px;
 }
 
 .blog-date {
     position: absolute;
     top: 15px;
     right: 15px;
     padding: 8px 10px;
     background: #f55422;
     color: #fff;
     font-size: 12px;
     font-weight: 500;
     text-align: center;
     z-index: 2;
     text-transform: uppercase;
 }
 .blog-date span {
     letter-spacing: 2px;
     font-family: 'Teko', sans-serif;
 }
 .blog-date i {
     font-size: 20px;
     font-style: normal;
     display: block;
 }
 
 .blog-area .single-blog .blog-content h5 a {
     font-size: 18px;
     font-weight: 500;
     color: #fff;
     margin-bottom: 10px;
     display: block;
 }
 
 .blog-bottom-text-link {
     margin-top: 25px;
 }
 
 /**************************************
  * 11.Contact Section Style
  *************************************/
 
 .contact-area {
     /* background: url(../img/contact-bg.png); */
     background-repeat: no-repeat;
     background-size: cover;
 }
 
 .contact-area .form input, .contact-area .form textarea {
     width: 100%;
     padding: 15px 0px;
     color: #fff;
     border: 0;
     font-size: 14px;
     border-bottom: 1px solid #353535;
     font-weight: 400;
     background: transparent;
 }
 
 #contact-form .button {
     margin: 0;
 }
 
 .form-group {
     margin-bottom: 25px;
 }
 
 button, input, textarea {
     color: #505050;
 }
 
 button, input, textarea:focus {
     border: none;
     outline: none;
 }
 
 .contact-area .form-message.success {
     background: #03b103;
     color: #fff;
     padding: 10px 15px;
     border-radius: 3px;
 }
 
 .contact-area .form-message.error {
     background: #ff4d15;
     color: #fff;
     padding: 10px 15px;
     border-radius: 3px;
 }
 
 /**************************************
  * 12. Map Section Style
  *************************************/
 .map-content #contact-map {
     position: relative;
     width: 100%;
     height: 500px;
     border: 0;
     margin-bottom: -2px;
 }
 
 /**************************************
  * 13. Footer Section Style
  *************************************/
 .footer-area {
     padding: 50px 0;
 }
 .footer-social-media {
     margin-bottom: 20px;
 }
 .footer-social-media a {
     margin: 0 10px;
     color: #fff;
     font-size: 16px;
     width: 35px;
     height: 35px;
     line-height: 35px;
     text-align: center;
     display: inline-block;
     position: relative;
     -webkit-transition: all .4s;
     transition: all .4s;
     z-index: 3;
 }
 
 .footer-social-media a:after {
     content: '';
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     position: absolute;
     border-radius: 10px;
     background: rgba(200, 200, 200, 0.07);
     z-index: -1;
     -webkit-transform: rotate(45deg);
     transform: rotate(45deg);
     -webkit-transition: all .4s;
     transition: all .4s;
 }
 .footer-social-media a:hover {
     color: #fff;
 }
 .footer-social-media a:hover:after  {
     background: #f55422;
 }
 
 .copyright-area {
 }
 
 .copyright-area p {
     color: #fff;
 }
 
 .copyright-area p a {
     color: #f55422;
     font-weight: 500;
 }